import React from 'react'

// Images
import outsideSize from 'img/outside_size.svg'
import insideSize from 'img/inside_size.svg'
import year from 'img/year.svg'
import bedroom from 'img/bedroom.svg'
import houseBuy from 'img/snel_house_buy.svg'
import houseRent from 'img/snel_house_rent.svg'
import thumbnail from 'img/thumbnail.png'

// Components
import { Paragraph } from 'components/StyledComponents'
import CustomLink from 'components/CustomLink'

// Third Party
import styled from 'styled-components'

const SingleHomeWrapper = styled.div`
  min-width: 250px;
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.color.main};
`

const Label = styled.div`
  top: 11px;
  left: 0;

  ${Paragraph} {
    white-space: nowrap;
  }
`

const ImgContainer = styled(CustomLink)`
  display: flex;
  overflow: hidden;
  border-radius: 15px;
  
  & img {
    object-fit: cover;
  }

  :hover {
    img.woningImage {
      transform: scale(1.2);
      transition: 0.25s;
    }
  }
`

const Detail = styled.div``

const Details = styled.div`
  @media (max-width: 360px) {
    flex-wrap: wrap;

    ${Detail} {
      width: 50%;
    }
  }
`

const SingleHome = ({ data: { node: data }, className }) => {
  const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0
  })

  const conditieChecker = () => {
    let returnString = '';

    if (data.recapWoning.koopconditie) {
      if (data.recapWoning.koopconditie === "VRIJ_OP_NAAM") {
        returnString = 'v.o.n.'
      } else if (data.recapWoning.koopconditie === "KOSTEN_KOPER") {
        returnString = 'k.k.'
      } else {
        returnString = 'N/A'
      }
    } else {
      returnString = ''
    }

    return returnString
  }

  return (
    <SingleHomeWrapper className={`${className ? `${className}` : ``}`}>
      <ImgContainer to={`/aanbod/woningaanbod/${data.slug}`} className="position-relative">
        <img className="woningImage" src={data.recapWoning.image ? data.recapWoning.image : thumbnail} alt="" width="100%" height="250" />
        {data.recapWoning.status !== 'INGETROKKEN' && data.recapWoning.status !== 'ONDER_OPTIE' && (
          <Label className="position-absolute">
            <div className="d-flex px-2 py-1 color-background-contrast">
              {data.recapWoning.koop && !data.recapWoning.huur && data.recapWoning.status === 'BESCHIKBAAR' && (
                <>
                  <img className="pr-2" src={houseBuy} alt="" />
                  <Paragraph className="text-uppercase font-size-s color-text-light font-weight-xl">Te koop</Paragraph>
                </>
              )}
              {data.recapWoning.koop && !data.recapWoning.huur && data.recapWoning.status === 'VERKOCHT_ONDER_VOORBEHOUD' && (
                <>
                  <img className="pr-2" src={houseBuy} alt="" />
                  <Paragraph className="text-uppercase font-size-s color-text-light font-weight-xl">Verkocht OVB</Paragraph>
                </>
              )}
              {data.recapWoning.koop && !data.recapWoning.huur && data.recapWoning.status === 'ONDER_BOD' && (
                <>
                  <img className="pr-2" src={houseBuy} alt="" />
                  <Paragraph className="text-uppercase font-size-s color-text-light font-weight-xl">Onder Bod</Paragraph>
                </>
              )}
              {data.recapWoning.koop && !data.recapWoning.huur && data.recapWoning.status === 'VERKOCHT' && (
                <>
                  <img className="pr-2" src={houseBuy} alt="" />
                  <Paragraph className="text-uppercase font-size-s color-text-light font-weight-xl">Verkocht</Paragraph>
                </>
              )}
              {data.recapWoning.huur && !data.recapWoning.koop && data.recapWoning.status === 'BESCHIKBAAR' && (
                <>
                  <img className="pr-2" src={houseRent} alt="" />
                  <Paragraph className="text-uppercase font-size-s color-text-light font-weight-xl">Te huur</Paragraph>
                </>
              )}
              {data.recapWoning.huur && !data.recapWoning.koop && data.recapWoning.status === 'VERHUURD' && (
                <>
                  <img className="pr-2" src={houseRent} alt="" />
                  <Paragraph className="text-uppercase font-size-s color-text-light font-weight-xl">Verhuurd</Paragraph>
                </>
              )}
              {data.recapWoning.huur && data.recapWoning.koop && (
                <>
                  <img className="pr-2" src={houseBuy} alt="" />
                  <Paragraph className="text-uppercase font-size-s color-text-light font-weight-xl">
                    Te koop
                    <strong className="pl-2"> / </strong>
                  </Paragraph>
                  <img className="pl-2 pr-2" src={houseRent} alt="" />
                  <Paragraph className="text-uppercase font-size-s color-text-light font-weight-xl">Te huur</Paragraph>
                </>
              )}
            </div>
          </Label>
        )}
      </ImgContainer>
      <div className="p-2">
        <CustomLink to={`/aanbod/woningaanbod/${data.slug}`}>
          <Paragraph className="font-weight-xl font-size-sm text-uppercase">{`${data.recapWoning.address}, ${data.recapWoning.city}`}</Paragraph>
        </CustomLink>
        <Paragraph className="font-size-sm text-uppercase">{`${formatter.format(data.recapWoning.price)},- ${conditieChecker()}`}</Paragraph>
        <Separator className="my-2" />
        <Details className="d-flex">
          {data.recapWoning.totaleOppervlakte !== null && (
            <Detail className="d-flex mr-3">
              <img className="pr-1" src={outsideSize} alt="" />
              <Paragraph>{`${data.recapWoning.totaleOppervlakte} m²`}</Paragraph>
            </Detail>
          )}
          <Detail className="d-flex mr-3">
            <img className="pr-1" src={insideSize} alt="" />
            <Paragraph>{`${data.recapWoning.totaleWoonoppervlakte} m²`}</Paragraph>
          </Detail>
          <Detail className="d-flex mr-3">
            <img className="pr-1" src={year} alt="" />
            <Paragraph>{data.recapWoning.buildYear}</Paragraph>
          </Detail>
          <Detail className="d-flex">
            <img className="pr-1" src={bedroom} alt="" />
            <Paragraph>{data.recapWoning.numberRooms}</Paragraph>
          </Detail>
        </Details>
      </div>
    </SingleHomeWrapper>
  )
}

export default SingleHome
