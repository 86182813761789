/* eslint-disable react/no-array-index-key */
import React from 'react'


// Components
import SingleHome from 'components/Aanbod/SingleHome'
import SingleBog from 'components/Aanbod/SingleBog'
import { NextArrow, PrevArrow } from 'components/Common/Arrows'

// Third Party
import styled from 'styled-components'
import Slider from 'react-slick'
import ButtonLineDefault from '../Elements/ButtonLineDefault'

const PreviewWrapper = styled.div``

const Title = styled.h2`
   font-size: 40px;
    font-weight: bold;
    margin-bottom: 3rem;

    @media (max-width: 991px){
      font-size: 25px;
    }
`

const StyledSlider = styled(Slider)`
  .slick-list {
    overflow: hidden;
    margin: 0 -15px;
  }

  .slick-slide {
    margin: 0 15px;
  }

  .slick-track {
    display: flex;
  }

  .slick-arrow {
    position: absolute;
  }
`

const Preview = ({ bog, data, title, loadMoreButton, className }) => (
  <PreviewWrapper className={`${className ? `${className}` : ``}`}>
    <div className="container py-5">
      <Title>{title ? `${title}` : `ONTDEK ONS NIEUWSTE AANBOD`}</Title>
      <StyledSlider
        className="d-flex position-relative"
        infinite
        speed={1000}
        slidesToShow={data.length < 3 ? data.length : 3}
        slidesToScroll={1}
        autoplay
        autoplaySpeed={6000}
        nextArrow={<NextArrow white />}
        prevArrow={<PrevArrow white />}
        responsive={[
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1
            }
          }
        ]}
      >
        {data.map((huis, index) => (
          <div key={index}>
            {bog ? (
              <SingleBog placeholder={false} key={index} data={huis} className="" />
            ) : (
              <SingleHome placeholder={false} key={index} data={huis} className="" />
            )}
          </div>
        ))}
      </StyledSlider>
      {loadMoreButton && (
        <div className="d-flex justify-content-center pt-5">
          <ButtonLineDefault to="/aanbod/woningaanbod">Bekijk het hele aanbod</ButtonLineDefault>
        </div>
      )}
    </div>
  </PreviewWrapper>
)

export default Preview